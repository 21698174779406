@use "sass:math"; @import "styles/_sass-variables.scss";@import "styles/_mixins.scss";
.LoginPage {
  background-color: var(--grey-page);
}

.LoginPageHeading {
  font-weight: 400;
}

.LoginPageFormContainer {
  padding-top: 120px;
}

.RemindPassword {
  display: block;
  width: fit-content;
  margin-top: 0;
  font-size: rem-calc(20);
  line-height: rem-calc(25px);
  text-decoration: none;
  color: var(--black);

  @media (min-width: $desktop-min-breakpoint) {
    margin-top: var(--spacing-base);
  }

  &:hover {
    text-decoration: underline;
  }
}
