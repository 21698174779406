@use "sass:math"; @import "styles/_sass-variables.scss";@import "styles/_mixins.scss";
.FormContainerTitle {
  font-weight: 400;
  margin-top: 96px;
  margin-bottom: 32px;

  @media (min-width: $desktop-min-breakpoint) {
    margin-top: 64px;
  }
}

.FormMessage {
  margin: 40px 0;
}

.FormFieldContainer {
  margin-bottom: var(--spacing-s);
  max-width: var(--tablet-content-max-width, 500px);

  @media (min-width: $desktop-min-breakpoint) {
    margin-bottom: var(--spacing-m);
  }
}

.FormFieldButtons {
  @media (min-width: 500px) {
    display: flex;
  }

  > * {
    margin-bottom: 16px;
    width: 100%;

    @media (min-width: 500px) {
      width: auto;
    }
  }

  * + * {
    @media (min-width: 500px) {
      order: -1;
      margin-right: 16px;
    }
  }
}

.AccountForm {
  padding: var(--spacing-m) 0;

  @media (min-width: $desktop-min-breakpoint) {
    padding: var(--spacing-xl) 0;
  }
}

.RegisterPageFormPage {
  padding: 0 0 var(--spacing-m);

  @media (min-width: $desktop-min-breakpoint) {
    padding: 0 0 var(--spacing-xl);
  }
}

.RegisterPageTitle {
  padding: 0 0 var(--spacing-m);

  @media (min-width: $desktop-min-breakpoint) {
    padding: 0 0 var(--spacing-xl);
  }
}

.pageContainer,
.pageContainerShort {
  p {
    margin: 0 0 var(--spacing-m);
  }
}

.pageContainer {
  padding: 120px 0;

  @media (min-width: $desktop-min-breakpoint) {
    padding-top: 180px;
  }
}

.pageContainerShort {
  padding: 60px 0;

  @media (min-width: $desktop-min-breakpoint) {
    padding-top: 120px;
  }
}

.ImageContainer {
  margin-top: var(--spacing-m);

  img {
    max-width: 100%;
    max-height: 450px;
  }

  @media (min-width: $desktop-min-breakpoint) {
    margin-top: unset;
  }
}
