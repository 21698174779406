@use "sass:math"; @import "styles/_sass-variables.scss";@import "styles/_mixins.scss";
.siteticker {
  width: 100%;

  background-color: var(--color-background);

  &__inner {
    padding: 0 calc(var(--grid-margin) - var(--gutter));
    margin: 0 auto;

    max-width: var(--page-width);

    &Column {
      position: relative;

      display: flex;
      align-items: center;

      width: 100%;
      height: var(--ticker-height);

      padding: 0 var(--gutter);

      &::before,
      &::after {
        content: '';
        position: absolute;
        z-index: 2;

        width: 22px;
        height: 100%;

        top: 0;

        background: linear-gradient(270deg, var(--color-background) 0%, rgba(0, 0, 0, 0) 100%);
      }

      &::before {
        left: 0;
        transform: rotate(-180deg);
      }

      &::after {
        right: 0;
        width: 110px;
        border-right: 15px solid var(--black);
      }
    }
  }
}

.tickerClose {
  background-color: transparent;
  color: var(--color-primary);

  border: none;

  font-weight: 400;
  font-size: 1rem;
  line-height: 1.25;

  position: absolute;
  z-index: 3;

  top: 0;
  right: var(--gutter);

  height: var(--ticker-height);

  display: flex;
  align-items: center;

  padding: 0 3px 0 8px;
}
