@use "sass:math"; @import "styles/_sass-variables.scss";@import "styles/_mixins.scss";
.menu {
  display: flex;
  flex-direction: row;
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
  z-index: 2;
  --link-color: var(--white);

  li {
    position: relative;
    margin-bottom: 8px;
    align-self: flex-start;
    padding: 0 calc(var(--gutter) * 2);

    a {
      display: block;
      font-size: rem-calc(20);
      line-height: 1.25;
      font-weight: 400;
      letter-spacing: 0.5px;
      text-decoration: none;
      margin: 0;
      white-space: nowrap;
      border-bottom: solid transparent 2px;

      @media (max-width: $mobile-max-breakpoint) {
        font-size: rem-calc(20);
      }

      &:hover {
        border-bottom: solid var(--black) 2px;
      }
    }
  }

  li:last-of-type {
    margin-bottom: var(--spacing-xl);
  }
}

.submenuContainer {
  position: absolute;
  transform: translate(-50%, 75%);
  pointer-events: none;
  opacity: 0;
  height: 0;
  transition: all 0.2s var(--transiton-timing-ease);
  // transition-delay: 0.5s;
  top: 3rem;
  left: 50%;
  padding-top: 2rem;
}

.topMenu:hover {
  .submenuContainer {
    pointer-events: auto;
    opacity: 1;
    height: auto;
    transform: translate(-50%, 0);
    transition: all 0.2s var(--transiton-timing-ease);
    /* fade in when hovered */
    // transition-delay: 0s; /* fade in immediately */
  }
}

.mobileMenuTrigger {
  display: flex;
  justify-content: flex-end;
}

.mobileMenu {
  .menu {
    display: flex;
    flex-direction: column;

    a {
      color: var(--black);
    }
  }

  &__bottomContent {
    margin-top: auto;
    padding: 0 calc(var(--gutter) * 2) 0 20px;
    line-height: 1.2;

    a {
      text-decoration: none;
      color: var(--black);
    }
  }

  &__bottomMenu {
    display: flex;
    list-style: none;
    padding: 0;
    margin-top: 27px;
    margin-bottom: 32px;

    a {
      margin-right: 8px;
      font-size: rem-calc(14);
      color: var(--charcoal);
      line-height: 1.3;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.logoutButton {
  margin: 0 0 var(--spacing-xl);
  --button-color: var(--white);
  --button-background: var(--black);
}

.support {
  border-top: 1px solid var(--grey-primary);
  color: var(--black);
  font-size: rem-calc(16);
  padding-top: var(--spacing-m);

  a {
    text-decoration: underline;
    font-size: rem-calc(16);
  }
}
