@use "sass:math"; @import "styles/_sass-variables.scss";@import "styles/_mixins.scss";
.breadcrumbs {
  margin: 0;

  --set-dropdown-width: ; // clears dropdown width

  display: flex;
  align-items: center;

  @media screen and (max-width: $mobile-max-breakpoint) {
    margin: 0 0 0 var(--spacing-s);
  }

  > ul {
    list-style: none;
    padding: 0;
    margin: 0;

    display: flex;
    align-items: center;
    gap: var(--spacing-m);

    @media screen and (max-width: $mobile-max-breakpoint) {
      > li:not(:last-of-type) {
        display: none;
      }
    }
  }

  &__link {
    display: flex;
    align-items: center;
    gap: var(--spacing-m);

    a {
      text-decoration: none;
      text-transform: capitalize;
      color: var(--header-color);

      @media screen and (min-width: 1120px) {
        max-width: none;
      }
    }

    svg {
      stroke: none;
      --icon-color: var(--header-color);

      @media screen and (max-width: $mobile-max-breakpoint) {
        display: none;
      }
    }
  }
}

.name {
  white-space: nowrap;
  @media (max-width: $mobile-max-breakpoint) {
    font-size: rem-calc(16);
    span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: calc(100vw - 200px);
    }
  }
}

.arrowDesktop {
  display: block;
  @media screen and (max-width: $tablet-max-breakpoint) {
    display: none;
  }
}

.arrowMobile {
  display: block;
  @media screen and (min-width: $desktop-min-breakpoint) {
    display: none;
  }
}

.dropdownMenu {
  display: flex;
  align-items: center;
  gap: var(--spacing-m);
  margin-left: var(--spacing-m);

  > [class^='Dropdown'] {
    --dropdown-color: var(--header-color);
    --dropdown-active-background-color: var(--white);
    --dropdown-background-color: transparent;
    --color-background: transparent;
    --dropdown-border-color: var(--grey-primary);
    --dropdown-list-background: var(--white);
    --dropdown-active-background-color: var(--grey-primary);

    z-index: 6;

    & > button[aria-expanded='true'] {
      border-color: transparent;
      --dropdown-color: var(--black);
      --dropdown-background-color: transparent;
      --icon-color: var(--black) !important;

      &:hover {
        background-color: transparent;
      }
    }
  }

  [class^='Dropdown_DropdownContainer'] {
    @media screen and (min-width: 1060px) {
      min-width: 202px;
    }
  }

  > button {
    align-self: stretch;
  }

  @media screen and (max-width: $tablet-max-breakpoint) {
    display: none;
  }
}
