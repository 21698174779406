@use "sass:math"; @import "styles/_sass-variables.scss";@import "styles/_mixins.scss";
.container {
  position: relative;

  hr {
    border: none;
    border-top: 1px solid var(--grey-mid);
    margin: var(--spacing-m) 0;

    @media (min-width: $desktop-min-breakpoint) {
      margin: var(--spacing-xl) 0;
    }

    &.hrSmall {
      margin-bottom: var(--spacing-base);

      @media (min-width: $desktop-min-breakpoint) {
        margin-bottom: var(--spacing-m);
      }
    }
  }

  .subscriptionToast {
    & > div {
      letter-spacing: 0.5px;
      align-items: center;

      > button:first-of-type {
        > span:first-of-type {
          width: 1.5em;
          height: 1.5em;
        }
      }
    }
  }
}

.footer-normal {
  .container {
    hr {
      border: none;
      border-top: 1px solid var(--grey-mid);
    }
  }

  --checkbox-icon: url('/checkmark-charcoal.svg');
  --checkbox-checked-background: var(--color-background);
  --checkbox-border-color: var(--color-primary);
}

.footer-black {
  --color-primary: var(--white);
  --color-background: var(--black);
  --footer-color: var(--color-primary);
  --footer-bg: var(--color-background);
  --color-link-hover: var(--color-primary);
  background-color: var(--footer-bg);
  --checkbox-icon: url('/checkmark-white.svg');
  --checkbox-checked-background: var(--color-background);
  --checkbox-border-color: var(--color-primary);
  --footer-button-background: var(--white);
  --footer-button-color: var(--black);
}

.footer-yellow-grey {
  --color-primary: var(--white);
  --color-background: var(--yellow-grey);
  --footer-color: var(--color-primary);
  --footer-bg: var(--yellow-grey);
  --color-link-hover: var(--color-primary);
  background-color: var(--yellow-grey);
  --checkbox-icon: url('/checkmark-white.svg');
  --checkbox-checked-background: var(--color-background);
  --checkbox-border-color: var(--color-primary);
  --footer-button-background: var(--white);
  --footer-button-color: var(--color-background);
}

.footer-blue {
  --color-primary: var(--white);
  --color-background: var(--blue-footer);
  --footer-color: var(--color-primary);
  --footer-bg: var(--color-background);
  --color-link-hover: var(--color-primary);
  background-color: var(--footer-bg);
  --checkbox-icon: url('/checkmark-white.svg');
  --checkbox-checked-background: var(--color-background);
  --checkbox-border-color: var(--color-primary);
  --footer-button-background: var(--white);
  --footer-button-color: var(--blue-footer);
  --button-disabled-background: var(--white);
  --button-disabled-opacity: 0.6;

  .newsletterSubmit {
    &:hover {
      background-color: var(--blue-footer);
    }
  }

  --footer-newsletter-input-border: var(--color-primary);
  --footer-newsletter-input-color: var(--color-primary);
}

.footer-charcoal {
  --color-primary: var(--white);
  --footer-color: var(--color-primary);
  background-color: var(--charcoal-footer);
  --footer-button-background: var(--white);
  --footer-button-color: var(--charcoal);
  --button-disabled-background: var(--white);
  --checkbox-icon: url('/checkmark-charcoal.svg');
  --checkbox-checked-background: var(--color-background);
  --checkbox-border-color: var(--color-background);
  --button-disabled-opacity: 0.6;
  --footer-newsletter-input-border: var(--color-primary);
  --footer-newsletter-input-color: var(--color-primary);
}

.footer-grey-light {
  --color-primary: var(--black);
  --color-background: var(--grey-page);
  --footer-color: var(--color-primary);
  --footer-bg: var(--color-background);
  --color-link-hover: var(--color-primary);
  --button-disabled-background: var(--grey-primary);
  background-color: var(--footer-bg);
  --footer-newsletter-input-border: var(--grey-primary);
  --footer-newsletter-input-color: var(--charcoal);
}

.footer-grey {
  --color-primary: var(--black);
  --color-background: var(--grey-footer);
  --footer-color: var(--color-primary);
  --footer-bg: var(--color-background);
  --color-link-hover: var(--color-primary);
  --button-disabled-background: var(--grey-primary);
  background-color: var(--footer-bg);
}

.footer-dark-grey {
  --color-primary: var(--white);
  --color-background: var(--charcoal);
  --footer-color: var(--white);
  --footer-bg: var(--color-background);
  --color-link-hover: var(--color-primary);
  background-color: var(--footer-bg);
}

.footerLinksContainer {
  display: flex;
  flex-flow: row wrap;
  align-content: flex-start;
  gap: var(--spacing-s);

  & > li {
    flex: 1 0 160px;
    padding: 0 var(--spacing-s) var(--spacing-s) 0;
    @media screen and (max-width: $mobile-max-breakpoint) {
      flex: 1 0 140px;
    }
  }
  // placeholder to even out items growing for unequal rows
  & > li:nth-last-child(1) {
    padding: 0;
    @media (min-width: 1126px) {
      display: initial;
    }
  }

  list-style: none;
  padding: 0;

  min-height: 181px;
  border-right: 1px solid var(--grey-mid);

  @media screen and (max-width: $mobile-max-breakpoint) {
    height: auto;
    border: none;
  }
}

.footerLink {
  text-decoration: none;
  border-bottom: 2px solid transparent;

  &:hover {
    border-bottom-color: var(--color-link-hover, --black);
  }
}

.socialContainer {
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    padding-bottom: var(--spacing-m);
  }

  @media screen and (max-width: $mobile-max-breakpoint) {
    padding-top: var(--spacing-m);
    border-top: 1px solid var(--grey-mid);
  }
}

.social {
  text-decoration: none;
  border-bottom: 2px solid transparent;

  &:hover {
    border-bottom-color: var(--color-link-hover, --black);
  }
}

.newsletter {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

.newsletterText {
  width: auto;
  align-self: flex-start;
  flex: 0 0 auto;
  width: 100%;
  margin-right: 15px;
  margin-bottom: var(--spacing-s);
  border-bottom: none;

  @media (min-width: $desktop-min-breakpoint) {
    width: fit-content;
    margin-bottom: 0;
  }
}

.newsletterForm {
  flex: 1 1;

  @media (min-width: $desktop-min-breakpoint) {
    width: 76%;
    margin-top: -7px;
  }
}

.newsletterFormContainer {
  display: flex;
  flex-wrap: wrap;
  row-gap: 10px;
}

.newsletterInput {
  flex: 1 1;
  margin-right: var(--spacing-s);

  // TO DO replace with input component
  border-radius: 50px;
  font-size: 16px;
  padding: 10px 20px 10px 20px;
  border: 1px solid var(--footer-newsletter-input-border, var(--white));
  background-color: var(--footer-bg);
  color: var(--footer-newsletter-input-color, var(--color-primary));

  &::placeholder {
    color: var(--footer-newsletter-input-color, var(--footer-color));
  }

  &:focus {
    border: 1px solid var(--footer-color, rgba(0, 0, 0, 0.3));
    color: var(--color-primary);
    outline: none;

    &::placeholder {
      color: var(--color-primary);
    }
  }

  @media (min-width: $tablet-min-breakpoint) {
    margin-right: var(--spacing-m);
    max-width: 69%;
  }
}

.footerSupport {
  text-align: center;

  @media (max-width: $mobile-max-breakpoint) {
    p {
      font-size: rem-calc(16);
    }
  }
}

.mailTo {
  color: var(--color-primary);
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.mobileTagline {
  padding: var(--spacing-m) 0;
  font-size: rem-calc(14);
  text-align: center;

  @media (max-width: $tablet-max-breakpoint) {
    border-bottom: 1px solid var(--grey-mid);
    padding: var(--spacing-s) 0 var(--spacing-m);
    margin-bottom: var(--spacing-s);
  }
}

.subFooter {
  display: flex;
  flex-direction: row;
  font-size: rem-calc(14);

  & > p {
    flex: 1;
    padding: 0 16px;

    @media (min-width: $desktop-min-breakpoint) {
      padding: 0;
    }
  }

  margin-bottom: var(--spacing-m);

  @media (min-width: $tablet-min-breakpoint) {
    font-size: inherit;
  }
  @media (min-width: $desktop-min-breakpoint) {
    margin-bttom: var(--spacing-xl);
  }
}

.subFooterCopy {
  color: var(--footer-color, var(--charcoal));
}

.subFooterTagline {
  text-align: center;
  font-size: 16px;
}

.subFooterNav {
  display: flex;
  flex-flow: row wrap;

  justify-content: flex-end;

  a:hover {
    text-decoration: underline;
  }
}

.subFooterPrivacy {
  color: var(--footer-color, var(--charcoal));
  text-decoration: none;
}

.subFooterTerms {
  color: var(--footer-color, var(--charcoal));
  text-decoration: none;
  margin-left: var(--spacing-m);
}

.acceptPolicy {
  margin-top: var(--spacing-s);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  div:first-child {
    flex: 0;
  }

  --checkbox-scale: 0.45;

  label {
    margin-bottom: 0;
    color: var(--charcoal);
  }

  &__text {
    flex: 1 0 auto;
    font-size: 12px;
    line-height: 1.3;
    letter-spacing: 0.5px;
    color: var(--color-primary);

    @media (max-width: $tablet-max-breakpoint) {
      width: 100%;
    }
  }

  &__link {
    color: var(--color-primary);

    &:hover {
      color: var(--color-link-hover);
    }
  }
}

@media (max-width: $mobile-max-breakpoint) {
  .subFooter {
    flex-direction: column-reverse;
  }

  .subFooterTagline {
    display: none;
  }

  .subFooterNav {
    justify-content: center;
  }

  .subFooterPrivacy {
    margin-right: var(--spacing-s);
  }

  .subFooterCopy {
    text-align: center;
    margin-top: 8px;
  }
}

.newsletterSubmit {
  padding: 10px 16px;
  flex: 0 2 auto;
  line-height: 1.43;
  background-color: var(--charcoal, var(--charcoal));
  color: var(--footer-white, var(--white));
  border: none;

  &:hover {
    color: var(--white);
    background-color: var(--charcoal);
  }
}
